import styled from 'styled-components';
import {device} from '../../../theme'

export const PoliciesWrapper = styled.section `
    padding-top: 100px;
    padding-bottom: 90px;
    background-color: #F8F8F8;
    
    ul{
        padding-left: 1.5rem;    
        margin-botton: 1rem;
        list-style: disc;
    }

    ul li{

    }

    h5{
        margin-top: 1rem;
    }

    p{
        margin: 0;
        margin-top: 0.3rem;
    }

    blockquote{
        margin: 54px 65px 44px 70px;
        padding: 0 0 0 20px;
        font-size: 18px;
        line-height: 1.78;
        font-weight: 600;
        border-left: 4px solid #eee;
        border-left-color: #d2a98e;
        position: relative;
        color: #333;
        @media ${device.small}{
            font-size: 16px;
            margin: 50px 70px 44px;
        }
        @media ${device.xsmall}{
            margin: 40px 30px;
        }
    }

    @media ${device.medium}{
        padding-top: 80px;
        padding-bottom: 70px;
    }
    @media ${device.small}{
        padding-top: 60px;
        padding-bottom: 50px;
    }
`;

export const SectionBottom = styled.div `
    margin-top: 30px;
`;